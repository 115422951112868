<template>
    <div class="c-navigation-links-list">
        <ul>
            <li
                v-for="navItem in navigationLinks"
                :key="navItem.displayName"
            >
                <BaseLink
                    :to="navItem.targetUrl || navItem.slug"
                    :target="navItem.target"
                    class="c-navigation-links-list__a"
                    @click="trackNavClick(navItem)"
                >
                    {{ navItem.displayName }}
                </BaseLink>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { NAVIGATION_CLICKED } from '~coreModules/core/js/global-event-constants';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';

export default {
    name: 'NavigationLinksList',
    components: {},
    props: {
        navigationLinks: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        trackNavClick(navItem) {
            this.trackGlobalEvent({
                type: NAVIGATION_CLICKED,
                data: {
                    label: navItem.slug || navItem.targetUrl,
                },
            });
        },
    },
};
</script>

<style lang="scss">
    .c-navigation-links-list {
        background: $nu-white;

        &__a {
            width: 100%;
            padding: $nu-spacer-2;
            border-bottom: 1px solid $nu-gray--light;
            color: $nu-black;
        }
    }
</style>
