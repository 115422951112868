<i18n>
[
    "header__signIn--label",
    "header__signOut--label",
]
</i18n>

<template>
    <div class="c-navigation-menu" :style="`--navContentBottomPadding: ${navContentBottomPadding}px`">
        <div class="c-navigation-menu__content-wrapper">
            <div class="c-navigation-menu__content-container">
                <slot></slot>
            </div>
            <div
                v-if="!!$slots.fixedCta"
                ref="ctaContainer"
                class="c-navigation-menu__fixed-cta-container"
            >
                <slot name="fixedCta"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CoreNavigationMenu',
    data: () => ({
        navContentBottomPadding: 0,
    }),
    mounted() {
        this.updateCTAOffset();
    },
    updated() {
        this.updateCTAOffset();
    },
    methods: {
        updateCTAOffset() {
            this.navContentBottomPadding = this.$refs.ctaContainer ? this.$refs.ctaContainer.offsetHeight : 0;
        },
    },
};
</script>

<style lang="scss">
    .c-navigation-menu {
        height: 100%;
        overflow: auto;

        &__content-wrapper {
            overflow: hidden;
            position: relative;
            height: 100%;
        }

        &__content-container {
            height: 100%;
            overflow-y: auto;

            &::after {
                display: block;
                content: ' ';
                height: var(--navContentBottomPadding)
            }
        }

        &__fixed-cta-container {
            border-top: 1px solid $nu-gray--light;
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
            left: 0;
            right: 0;
            height: 48px;
            width: 100%;
            background-color: $nu-white;
            text-align: center;
        }
    }
</style>
