<template>
    <div class="c-navigation-links-menu">
        <NavigationLinksList :navigationLinks="navigationLinks" />
    </div>
</template>

<script>

import NavigationLinksList from '~coreModules/nav/components/NavigationLinksList.vue';

export default {
    name: 'NavigationLinksMenu',
    components: {
        NavigationLinksList,
    },
    props: {
        navigationLinks: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
</style>
