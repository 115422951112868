<template>
    <div
        class="c-async-content-fadein"
        :class="{
            'c-async-content-fadein--is-mounted': beginFadeInContent,
        }"
    >
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'AsyncContentFadeIn',
    props: {
        hasMetMinimLoadTime: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['contentRendered'],
    data() {
        return {
            isMounted: false,
        };
    },
    computed: {
        beginFadeInContent() {
            return this.isMounted && this.hasMetMinimLoadTime;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$emit('contentRendered', false);
            this.isMounted = true;
        });
    },
};
</script>

<style lang="scss">
    .c-async-content-fadein {
        height: 100%;
        overflow: hidden;
        opacity: 0;
        transition: 750ms ease;

        &--is-mounted {
            opacity: 1;
            overflow: unset;
        }
    }
</style>
